<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-lg" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
            Report Request
          </h3>
        </div>
      </div>
    </div>
    <div>
      <b-tabs content-class="mt-3" align="center">
        <b-tab title="Buyback Report" active>
          <b-container class="bv-example-row mb-5">
            <div v-if="!user.partner">
              <b-form-group label="Select An Option" v-slot="{ ariaDescribedby }">
                <b-form-radio v-model="selected_option" :aria-describedby="ariaDescribedby" name="some-radios"
                  value="A">IMEI</b-form-radio>
                <b-form-radio v-model="selected_option" :aria-describedby="ariaDescribedby" name="some-radios"
                  value="B">Reference</b-form-radio>
                <b-form-radio v-model="selected_option" :aria-describedby="ariaDescribedby" name="some-radios"
                  value="C">Partner</b-form-radio>
                <b-form-radio v-model="selected_option" :aria-describedby="ariaDescribedby" name="some-radios"
                  value="D">Liquidator</b-form-radio>
              </b-form-group>

              <!-- <div class="mt-3">Selected: <strong>{{ selected }}</strong></div> -->
            </div>
            <b-row>
              <b-col v-if="selected_option !== 'A' && selected_option !== 'B'">
                <div>
                  <b-form-input id="date" v-model="selected" type="date"></b-form-input>
                  <div class="mt-3">
                    Selected: <strong>{{ selected }}</strong>
                  </div>
                </div>
              </b-col>
              <b-col v-if="selected_option !== 'A' && selected_option !== 'B'">
                <div>
                  <b-form-input id="date" v-model="selected1" type="date"></b-form-input>
                  <div class="mt-3">
                    Selected: <strong>{{ selected1 }}</strong>
                  </div>
                </div>
              </b-col>
              <b-col v-if="selected_option === 'A'">
                <div>
                  <b-form-input v-model="imei" placeholder="Imei"></b-form-input>
                  <div class="mt-2">Value: {{ imei }}</div>
                </div>
              </b-col>
              <b-col v-if="selected_option === 'B'">
                <div>
                  <b-form-input v-model="ref" placeholder="Reference"></b-form-input>
                  <div class="mt-2">Value: {{ ref }}</div>
                </div>
              </b-col>
              <b-col v-if="user.partner">
                <div>
                  <b-form-input v-model="imei" placeholder="Imei"></b-form-input>
                  <div class="mt-2">Value: {{ imei }}</div>
                </div>
              </b-col>
              <b-col v-if="user.partner">
                <div>
                  <b-form-input v-model="ref" placeholder="Reference"></b-form-input>
                  <div class="mt-2">Value: {{ ref }}</div>
                </div>
              </b-col>
              <b-col v-if="selected_option === 'C'">
                <div class="" v-if="!user.partner">
                  <b-form-select v-model="partner" :options="options" class="">
                    <!-- This slot appears above the options from 'options' prop -->
                    <template #first>
                      <b-form-select-option :value="null" disabled>Partner List</b-form-select-option>
                    </template>
                    <b-form-select-option value="all">ALL</b-form-select-option>

                    <!-- These options will appear after the ones from 'options' prop -->
                    <b-form-select-option v-for="i in data4" :key="i" :value="i.id">
                      {{ i.name }}
                    </b-form-select-option>
                    <!-- <b-form-select-option value="D">Option D</b-form-select-option> -->
                  </b-form-select>
                </div>
              </b-col>
              <b-col v-if="!user.partner && selected_option === 'D'">
                <div class="">
                  <b-form-select v-model="vendor" :options="options" class="">
                    <!-- This slot appears above the options from 'options' prop -->
                    <template #first>
                      <b-form-select-option :value="null" disabled>Liquidator List</b-form-select-option>
                    </template>
                    <b-form-select-option value="all">ALL</b-form-select-option>

                    <!-- These options will appear after the ones from 'options' prop -->
                    <b-form-select-option v-for="i in data5" :key="i" :value="i.id">
                      {{ i.name }}
                    </b-form-select-option>
                    <!-- <b-form-select-option value="D">Option D</b-form-select-option> -->
                  </b-form-select>
                </div>
              </b-col>
              <b-col>
                <div>
                  <button type="button" class="btn btn-success" @click="Send_Request()" v-if="!user.partner">
                    Confirm Request
                  </button>
                  <button type="button" class="btn btn-success" v-if="user.partner" @click="Send_Request_partner()">
                    Confirm Request
                  </button>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <div v-if="!user.partner">
            <div v-if="data" class="text-right">
              <vue-excel-xlsx :data="data" :columns="json_fields" :file-name="'file'" :file-type="'xlsx'"
                :sheet-name="'sheet'">
                <button type="button" class="btn btn-success">
                  Download Excel
                </button>
              </vue-excel-xlsx>
            </div>
          </div>
          <div v-else>
            <div v-if="data" class="text-right">
              <vue-excel-xlsx :data="data" :columns="json_fields4" :file-name="'file'" :file-type="'xlsx'"
                :sheet-name="'sheet'">
                <button type=" button" class="btn btn-success">
                  Download Excel
                </button>
              </vue-excel-xlsx>
            </div>
          </div>
          <div class="text-center" v-if="empty_data">
            <p>{{ empty_data }}</p>
          </div>
          <div class="block w-full overflow-x-auto" v-else-if="data">
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Buyback ID
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Partner
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    User Name
                  </th>

                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    User ID
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Location ID
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Location Name
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Date
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Time
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="i in data" :key="i" @click="goToBuyback(i.id)">
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.id }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.partner }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.user }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.user_id }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.location_id }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.location }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.date }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.time }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab title="Liquidator Report" v-if="!user.partner">
          <b-container class="bv-example-row mb-5">
            <b-row>
              <b-col>
                <div>
                  <b-form-input id="date" v-model="selected" type="date"></b-form-input>
                  <div class="mt-3">
                    Selected: <strong>{{ selected }}</strong>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div>
                  <b-form-input id="date" v-model="selected1" type="date"></b-form-input>
                  <div class="mt-3">
                    Selected: <strong>{{ selected1 }}</strong>
                  </div>
                </div>
              </b-col>

              <b-col>
                <div class="">
                  <b-form-select v-model="vendor2" :options="options" class="">
                    <!-- This slot appears above the options from 'options' prop -->
                    <template #first>
                      <b-form-select-option :value="null" disabled>Liquidator List</b-form-select-option>
                    </template>
                    <b-form-select-option value="all">ALL</b-form-select-option>

                    <!-- These options will appear after the ones from 'options' prop -->
                    <b-form-select-option v-for="i in data5" :key="i" :value="i.id">
                      {{ i.name }}
                    </b-form-select-option>
                    <!-- <b-form-select-option value="D">Option D</b-form-select-option> -->
                  </b-form-select>
                </div>
              </b-col>
              <b-col>
                <div>
                  <button type="button" class="btn btn-success" @click="Send_Request3()">
                    Confirm Request
                  </button>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <div class="text-right" v-if="data3">
            <vue-excel-xlsx :data="data3" :columns="json_fields3" :file-name="'file'" :file-type="'xlsx'"
              :sheet-name="'sheet'">
              <button type="button" class="btn btn-success">
                Download Excel
              </button>
            </vue-excel-xlsx>
          </div>
          <div class="text-center" v-if="empty_data2">
            <p>{{ empty_data2 }}</p>
          </div>
          <div class="block w-full overflow-x-auto" v-else-if="data3">
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Buyback ID
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Category
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Brand
                  </th>

                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Model Description
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Offerd Price
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Liquidator Name
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Buyback Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="i in data3" :key="i">
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.id }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.cate }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.brand }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.device }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.trade_price }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.partner }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.date }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab title="Price Report" v-if="!user.partner">
          <b-container class="bv-example-row mb-5">
            <b-row>
              <b-col>
                <div>
                  <b-form-input id="date" v-model="selected" type="date"></b-form-input>
                  <div class="mt-3">
                    Selected: <strong>{{ selected }}</strong>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div>
                  <b-form-input id="date" v-model="selected1" type="date"></b-form-input>
                  <div class="mt-3">
                    Selected: <strong>{{ selected1 }}</strong>
                  </div>
                </div>
              </b-col>

              <b-col>
                <div class="">
                  <b-form-select v-model="vendor3" :options="options" class="">
                    <!-- This slot appears above the options from 'options' prop -->
                    <template #first>
                      <b-form-select-option :value="null" disabled>Liquidator List</b-form-select-option>
                    </template>
                    <b-form-select-option value="all">ALL</b-form-select-option>

                    <!-- These options will appear after the ones from 'options' prop -->
                    <b-form-select-option v-for="i in data5" :key="i" :value="i.id">
                      {{ i.name }}
                    </b-form-select-option>
                    <!-- <b-form-select-option value="D">Option D</b-form-select-option> -->
                  </b-form-select>
                </div>
              </b-col>
              <b-col>
                <div>
                  <button type="button" class="btn btn-success" @click="Send_Request2()">
                    Confirm Request
                  </button>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <div v-if="data2">
            <vue-excel-xlsx :data="data2" :columns="json_fields2" :file-name="'file'" :file-type="'xlsx'"
              :sheet-name="'sheet'">
              <button type="button" class="btn btn-success">
                Download Excel
              </button>
            </vue-excel-xlsx>
          </div>
          <div class="text-center" v-if="empty_data3">
            <p>{{ empty_data3 }}</p>
          </div>
          <div class="block w-full overflow-x-auto" v-else-if="data2" style="height: 350px">
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Category
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Brand
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Model
                  </th>

                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Condition
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Liquidatior
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Date
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="i in data2" :key="i">
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.cate }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.brand }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.name }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.con }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.p }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.date }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.active }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab title="GBB Report">
          <b-container class="bv-example-row mb-5">
            <div v-if="!user.partner">
              <b-form-group label="Select An Option" v-slot="{ ariaDescribedby }">
                <b-form-radio v-model="selected_option" :aria-describedby="ariaDescribedby" name="some-radios"
                  value="A">IMEI</b-form-radio>
                <b-form-radio v-model="selected_option" :aria-describedby="ariaDescribedby" name="some-radios"
                  value="B">Reference</b-form-radio>
                <b-form-radio v-model="selected_option" :aria-describedby="ariaDescribedby" name="some-radios"
                  value="C">Partner</b-form-radio>
              </b-form-group>
            </div>
            <b-row v-if="selected_option === 'A'">
              <b-col>
                <div>
                  <b-form-input v-model="imei" placeholder="Imei"></b-form-input>
                  <div class="mt-2">Value: {{ imei }}</div>
                </div>
              </b-col>
              <b-col>
                <div>
                  <button type="button" class="btn btn-success" @click="Send_Request_GBB()">
                    Confirm Request
                  </button>
                </div>
              </b-col>
            </b-row>
            <b-row v-if="selected_option === 'B'">
              <b-col>
                <div>
                  <b-form-input v-model="ref" placeholder="Reference"></b-form-input>
                  <div class="mt-2">Value: {{ ref }}</div>
                </div>
              </b-col>
              <b-col>
                <div>
                  <button type="button" class="btn btn-success" @click="Send_Request_GBB()">
                    Confirm Request
                  </button>
                </div>
              </b-col>
            </b-row>
            <b-row v-if="selected_option === 'C' || user.partner">

              <b-col>
                <div>
                  <b-form-input id="date" v-model="selected" type="date"></b-form-input>
                  <div class="mt-3">
                    Selected: <strong>{{ selected }}</strong>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div>
                  <b-form-input id="date" v-model="selected1" type="date"></b-form-input>
                  <div class="mt-3">
                    Selected: <strong>{{ selected1 }}</strong>
                  </div>
                </div>
              </b-col>

              <b-col v-if="!user.partner">
                <div class="">
                  <b-form-select v-model="partner_selected" :options="options" class="">
                    <!-- This slot appears above the options from 'options' prop -->
                    <!-- <template #first>
                      <b-form-select-option :value="null" disabled>Partner List</b-form-select-option>
                    </template> -->
                    <b-form-select-option :value="null">ALL Partner</b-form-select-option>

                    <!-- These options will appear after the ones from 'options' prop -->
                    <b-form-select-option v-for="i in data4" :key="i" :value="i.id">
                      {{ i.name }}
                    </b-form-select-option>
                    <!-- <b-form-select-option value="D">Option D</b-form-select-option> -->
                  </b-form-select>
                </div>
              </b-col>
              <b-col v-if="!user.partner">
                <div class="">
                  <b-form-select v-model="records_selected" :options="options" class="">
                    <!-- This slot appears above the options from 'options' prop -->
                    <template #first>
                      <b-form-select-option :value="null" disabled>Select Records</b-form-select-option>
                    </template>
                    <b-form-select-option value="abb_onboarding">Onboarding Records</b-form-select-option>
                    <b-form-select-option value="abb_claim_records">Claim Records</b-form-select-option>

                    <!-- These options will appear after the ones from 'options' prop -->
                    <!-- <b-form-select-option v-for="i in data4" :key="i" :value="i.id">
                      {{ i.name }}s
                    </b-form-select-option> -->
                    <!-- <b-form-select-option value="D">Option D</b-form-select-option> -->
                  </b-form-select>
                </div>
              </b-col>
              <b-col>
                <div>
                  <button type="button" class="btn btn-success" @click="Send_Request_GBB()">
                    Confirm Request
                  </button>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <div v-if="partner_data">
            <vue-excel-xlsx :data="partner_data" :columns="json_fields_partner_data" :file-name="'file'"
              :file-type="'xlsx'" :sheet-name="'sheet'">
              <button type="button" class="btn btn-success">
                Download Excel
              </button>
            </vue-excel-xlsx>
          </div>
          <div class="text-center" v-if="empty_data3">
            <p>{{ empty_data3 }}</p>
          </div>
          <div class="block w-full overflow-x-auto" v-else-if="partner_data" style="height: 350px">
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Ref#
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Device
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    IMEI
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Condition
                  </th>

                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Price
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Date
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Partner
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    user
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Location
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="i in partner_data" :key="i" @click="goToGBB(i.id)">
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.ref }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.device }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.imei }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.condition }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.amount }} {{ i.currency }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.claim_date }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.partner }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.user }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.location }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab title="Users Logs" v-if="!user.partner">
          <b-container class="bv-example-row mb-5">
            <b-row>
              <b-col>
                <div>
                  <b-form-input id="date" v-model="selected" type="date"></b-form-input>
                  <div class="mt-3">
                    Selected: <strong>{{ selected }}</strong>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div>
                  <b-form-input id="date" v-model="selected1" type="date"></b-form-input>
                  <div class="mt-3">
                    Selected: <strong>{{ selected1 }}</strong>
                  </div>
                </div>
              </b-col>

              <b-col v-if="!user.partner">
                <div class="">
                  <b-form-select v-model="partner_selected" :options="options" class="">
                    <b-form-select-option :value="null">ALL Partner</b-form-select-option>
                    <b-form-select-option v-for="i in data4" :key="i" :value="i.id">
                      {{ i.name }}
                    </b-form-select-option>
                  </b-form-select>
                </div>
              </b-col>
              <b-col>
                <div>
                  <button type="button" class="btn btn-success" @click="Send_Request_user_logs()">
                    Confirm Request
                  </button>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <div v-if="user_logs">
            <vue-excel-xlsx :data="user_logs" :columns="json_fields_user_logs" :file-name="'user_logs'"
              :file-type="'xlsx'" :sheet-name="'sheet'">
              <button type="button" class="btn btn-success">
                Download Excel
              </button>
            </vue-excel-xlsx>
          </div>
          <div class="text-center" v-if="empty_data3">
            <p>{{ empty_data3 }}</p>
          </div>
          <div class="block w-full overflow-x-auto" v-else-if="user_logs" style="height: 350px">
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    ID
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Sales Representative
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Partner
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Location
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Device
                  </th>

                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Event
                  </th>
                  <th
                    class="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
    ]">
                    Date & Time
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="i in user_logs" :key="i" @click="goToGBB(i.id)">
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.id }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.name }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.partner }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.location }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.device }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.event }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ i.date }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import TableDropdown from "@/components/Dropdowns/TableDropdown.vue";

import bootstrap from "@/assets/img/bootstrap.jpg";
import angular from "@/assets/img/angular.jpg";
import sketch from "@/assets/img/sketch.jpg";
import react from "@/assets/img/react.jpg";
import vue from "@/assets/img/react.jpg";
import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";
import api from "../../api";

export default {
  data() {
    return {
      user_logs: null,
      empty_data: null,
      empty_data2: null,
      empty_data3: null,
      loc_data: null,
      selected: null,
      selected1: null,
      selected2: null,
      bootstrap,
      records_selected: null,
      data4: null,
      data5: null,
      angular,
      sketch,
      react,
      imei: null,
      selected_option: null,
      ref: null,
      vue,
      team1,
      team2,
      team3,
      team4,
      data: null,
      data2: null,
      partner_data: null,
      data3: null,
      vendor: null,
      vendor2: null,
      vendor3: null,
      partner_selected: null,
      partner: null,
      user: this.$store.state.user,
      json_fields: [
        { label: "ID", field: "id" },
        { label: "Status", field: "status" },
        { label: "Partner", field: "partner" },
        { label: "User Name", field: "user" },
        { label: "USer ID", field: "user_id" },
        { label: "Location ID", field: "location_id" },
        { label: "Location Name", field: "location" },
        { label: "Date", field: "date" },
        { label: "Time", field: "time" },
        { label: "IMEI", field: "imei" },
        {
          label: "Trade-in Price offered to the Retailer",
          field: "trade_price",
        },
        {
          label: "Trade-in Price offered by Liquidator",
          field: "partner_price",
        },
        { label: "Category", field: "cate" },
        { label: "Brand", field: "brand" },
        { label: "Device", field: "device" },
        { label: "Grade", field: "grade" },
        { label: "Customer Name", field: "cus_name" },
        { label: "Customer Email", field: "cus_email" },
        { label: "Customer Number", field: "cus_mobile" },
        { label: "Customer Address", field: "cus_add" },
        { label: "Promotion", field: "pro_series" },
        { label: "New Device", field: "pro_name" },
        { label: "Device Entered", field: "pro_device" },
        { label: "Top-up Value", field: "pro_price" },
        { label: "New Device IMEI", field: "pro_imei" },
        { label: "Liquidator ID", field: "liquidator_id" },
        { label: "Liquidator Name", field: "liquidator_name" },
        { label: "Liquidator Address", field: "liquidator_Address" },
      ],
      json_fields4: [
        { label: "ID", field: "id" },
        { label: "Status", field: "status" },
        { label: "Partner", field: "partner" },
        { label: "User Name", field: "user" },
        { label: "USer ID", field: "user_id" },
        { label: "Location ID", field: "location_id" },
        { label: "Location Name", field: "location" },
        { label: "Date", field: "date" },
        { label: "Time", field: "time" },
        { label: "IMEI", field: "imei" },
        {
          label: "Trade-in Price offered to the Retailer",
          field: "trade_price",
        },
        { label: "Category", field: "cate" },
        { label: "Brand", field: "brand" },
        { label: "Device", field: "device" },
        { label: "Grade", field: "grade" },
        { label: "Customer Name", field: "cus_name" },
        { label: "Customer Email", field: "cus_email" },
        { label: "Customer Number", field: "cus_mobile" },
        { label: "Customer Address", field: "cus_add" },
        { label: "Promotion", field: "pro_series" },
        { label: "New Device", field: "pro_name" },
        { label: "Device Entered", field: "pro_device" },

        { label: "Top-up Value", field: "pro_price" },
        { label: "New Device IMEI", field: "pro_imei" },
      ],
      json_fields3: [
        { label: "ID", field: "id" },
        { label: "Partner", field: "partner" },
        { label: "Date", field: "date" },
        { label: "Time", field: "time" },
        {
          label: "Trade-in Price offered to the Customer",
          field: "trade_price",
        },
        { label: "Category", field: "cate" },
        { label: "Brand", field: "brand" },
        { label: "Device", field: "device" },
      ],
      json_fields_partner_data: [
        { label: "ID", field: "ref" },
        { label: "Device", field: "device" },
        { label: "IMEI", field: "imei" },
        { label: "Condition", field: "condition" },
        { label: "Price", field: "amount" },
        { label: "Date", field: "claim_date" },
        { label: "Partner", field: "partner" },
        { label: "user", field: "user" },
        { label: "Location", field: "location" },
        {
          label: "New Devices",
          field: "new_device[0].name"
        },
        {
          label: "IMEI",
          field: "new_device[0].imei"
        },
        {
          label: "Amount",
          field: "new_device[0].amount"
        },
        {
          label: "Remarks",
          field: "new_device[0].remarks"
        },
        {
          label: "New Devices 2",
          field: "new_device[1].name"
        },
        {
          label: "IMEI",
          field: "new_device[1].imei"
        },
        {
          label: "Amount",
          field: "new_device[1].amount"
        },
        {
          label: "Remarks",
          field: "new_device[1].remarks"
        },
        {
          label: "New Devices 3",
          field: "new_device[2].name"
        },
        {
          label: "IMEI",
          field: "new_device[2].imei"
        },
        {
          label: "Amount",
          field: "new_device[2].amount"
        },
        {
          label: "Remarks",
          field: "new_device[2].remarks"
        },
      ],
      json_fields2: [
        { label: "Category", field: "cate" },
        { label: "Brand", field: "brand" },
        { label: "Model", field: "name" },
        { label: "Condition", field: "con" },
        { label: "Liquidatior", field: "p" },
        { label: "Date", field: "date" },
        { label: "Status", field: "active" },
      ],
      json_fields_user_logs: [
        { label: "ID", field: "id" },
        { label: "Sales Representative", field: "name" },
        { label: "Partner", field: "partner" },
        { label: "Location", field: "location" },
        { label: "Device", field: "device" },
        { label: "Event", field: "event" },
        { label: "Date & Time", field: "date" }
      ],
      json_data: [],
    };
  },
  components: {
    TableDropdown,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    Send_Request: function () {
      var token_data = JSON.parse(localStorage.getItem("token_data"));

      if (this.selected_option === "A") {
        this.selected = null;
        this.selected1 = null;
        this.partner = null;
        this.vendor = null;
        this.ref = null;
      } else if (this.selected_option === "B") {
        this.selected = null;
        this.selected1 = null;
        this.partner = null;
        this.vendor = null;
        this.imei = null;
      } else if (this.selected_option === "C") {
        this.ref = null;
        this.vendor = null;
        this.imei = null;
      } else if (this.selected_option === "D") {
        this.ref = null;
        this.patner = null;
        this.imei = null;
      } else if (this.selected_option === "E") {
        this.ref = null;
        this.imei = null;
      }
      const inputs = {
        start: this.selected,
        end: this.selected1,
        country: this.$store.state.partner,
        patner: this.partner,
        vendor: this.vendor,
        imei: this.imei,
        ref: this.ref,
        user_token: localStorage.getItem("user_token"),
        token_data: token_data,
      };
      api
        .request(process.env.VUE_APP_Get_Buyback_report, inputs)
        .then((response) => {
          const data = response.data;
          if (data.errorcode) {
            this.errormessage = data.errormessage;
          } else if (data.result && typeof data.result === "object") {
            // console.log(data.result)

            // this.data = data.result
            if (data.result.empty) {
              this.empty_data = data.result.empty;
              this.data = null;
            } else {
              this.data = data.result;
              this.empty_data = null;
            }
          } else if (data.result && typeof data.result === "string") {
            this.errormessage = data.result;
          }
        })
        .catch((error) => {
          this.errormessage = error;
        });
    },
    Send_Request_partner: function () {
      var token_data = JSON.parse(localStorage.getItem("token_data"));
      if (this.imei) {
        this.selected = null;
        this.selected1 = null;
      }
      if (this.ref) {
        this.selected = null;
        this.selected1 = null;
      }
      const inputs = {
        start: this.selected ? this.selected : "null",
        end: this.selected1 ? this.selected1 : "null",
        patner: this.$store.state.user.uid,
        imei: this.imei ? this.imei : "null",
        ref: this.ref ? this.ref : "null",
        user_token: localStorage.getItem("user_token"),
        token_data: token_data,
      };
      api
        .request(process.env.VUE_APP_Price_Data_Partner, inputs)
        .then((response) => {
          const data = response.data;
          if (data.errorcode) {
            this.errormessage = data.errormessage;
          } else if (data.result && typeof data.result === "object") {
            if (data.result.empty) {
              this.empty_data = data.result.empty;
              this.data = null;
            } else {
              this.data = data.result;
              this.empty_data = null;
            }
          } else if (data.result && typeof data.result === "string") {
            this.errormessage = data.result;
          }
        })
        .catch((error) => {
          this.errormessage = error;
        });
    },
    Send_Request3: function () {
      var token_data = JSON.parse(localStorage.getItem("token_data"));
      const inputs = {
        start: this.selected,
        end: this.selected1,
        country: this.$store.state.partner,
        vendor: this.vendor2,
        user_token: localStorage.getItem("user_token"),
        token_data: token_data,
      };
      api
        .request(process.env.VUE_APP_Get_Liquidator_report, inputs)
        .then((response) => {
          const data = response.data;
          if (data.errorcode) {
            this.errormessage = data.errormessage;
          } else if (data.result && typeof data.result === "object") {
            // this.data3 = data.result
            if (data.result.empty) {
              this.empty_data2 = data.result.empty;
              this.data3 = null;
            } else {
              this.data3 = data.result;
              this.empty_data2 = null;
            }
          } else if (data.result && typeof data.result === "string") {
            this.errormessage = data.result;
          }
        })
        .catch((error) => {
          this.errormessage = error;
        });
    },
    Send_Request2: function () {
      var token_data = JSON.parse(localStorage.getItem("token_data"));
      const inputs = {
        start: this.selected,
        end: this.selected1,
        country: this.$store.state.partner,
        vendor: this.vendor3,
        user_token: localStorage.getItem("user_token"),
        token_data: token_data,
      };
      api
        .request(process.env.VUE_APP_Get_Price_data_Report, inputs)
        .then((response) => {
          const data = response.data;
          if (data.errorcode) {
            this.errormessage = data.errormessage;
          } else if (data.result && typeof data.result === "object") {
            // this.data2 = data.result
            if (data.result.empty) {
              this.empty_data3 = data.result.empty;
              this.data2 = null;
            } else {
              this.data2 = data.result;
              this.empty_data3 = null;
            }
          } else if (data.result && typeof data.result === "string") {
            this.errormessage = data.result;
          }
        })
        .catch((error) => {
          this.errormessage = error;
        });
    },
    Send_Request_GBB: function () {
      if (!this.user.partner) {
        if (this.selected_option === "A") {
          if (this.imei === null) {
            this.$bvToast.toast('Enter the imei number', {
              title: 'Record Type',
              variant: 'danger',
              toaster: 'b-toaster-top-center'
            })
          }
        }
        if (this.selected_option === "B") {
          if (this.ref === null) {
            this.$bvToast.toast('Enter the reference number', {
              title: 'Record Type',
              variant: 'danger',
              toaster: 'b-toaster-top-center'
            })
          }
        }
        if (this.selected_option === "C") {
          if (this.selected === null || this.selected1 === null) {
            this.$bvToast.toast('Date not set', {
              title: 'Date',
              variant: 'danger',
              toaster: 'b-toaster-top-center'
            })
            return
          }
          if (this.records_selected === null) {
            this.$bvToast.toast('Select Record Type', {
              title: 'Record Type',
              variant: 'danger',
              toaster: 'b-toaster-top-center'
            })
            return
          }
        }

      }

      var token_data = JSON.parse(localStorage.getItem("token_data"));
      const inputs = {
        startDate: this.selected,
        endDate: this.selected1,
        country: this.$store.state.partner,
        partner: this.partner_selected,
        user_token: localStorage.getItem("user_token"),
        token_data: token_data,
        user_id: this.$store.state.user.uid,
        records: this.records_selected,
        partner_data: this.user.partner || null,
        ref_data: this.ref || null,
        imei_data: this.imei || null
      };
      api
        .request(process.env.VUE_APP_ABB_CLAIM_LIST, inputs)
        .then((response) => {
          const data = response.data;
          if (data.errorcode) {
            this.errormessage = data.errormessage;
          } else if (data.result && typeof data.result === "object") {
            // this.data2 = data.result
            if (data.result.empty) {
              this.empty_data3 = data.result.empty;
              this.partner_data = null;
            } else {
              this.partner_data = data.result;
              this.empty_data3 = null;
            }
          } else if (data.result && typeof data.result === "string") {
            this.errormessage = data.result;
          }
          this.imei = null
          this.ref = null
        })
        .catch((error) => {
          this.errormessage = error;
        });
    },
    Send_Request_user_logs: function () {
      if (this.selected === null || this.selected1 === null) {
        this.$bvToast.toast('Date not set', {
          title: 'Date',
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        })
        return
      }
      const inputs = {
        start_date: this.selected,
        end_date: this.selected1,
        partner_id: this.partner_selected
      };
      api
        .request(process.env.VUE_APP_GET_USER_LOGS, inputs)
        .then((response) => {
          const data = response.data;
          this.user_logs = data.result;
          this.empty_data3 = data.result.empty;
        })
        .catch((error) => {
          this.errormessage = error;
        });
    },
    getDetails: function (id) {
      var token_data = JSON.parse(localStorage.getItem("token_data"));
      const inputs = {
        country: this.$store.state.partner,
        user_token: localStorage.getItem("user_token"),
        token_data: token_data,
      };
      api
        .request(process.env.VUE_APP_Get_Pricing_Data3, inputs)
        .then((response) => {
          const data = response.data;
          if (data.errorcode) {
            this.errormessage = data.errormessage;
          } else if (data.result && typeof data.result === "object") {
            this.data4 = data.result;
          } else if (data.result && typeof data.result === "string") {
            this.errormessage = data.result;
          }
        })
        .catch((error) => {
          this.errormessage = error;
        });
    },
    getDetails2: function (id) {
      var token_data = JSON.parse(localStorage.getItem("token_data"));
      const inputs = {
        country: this.$store.state.partner,
        user_token: localStorage.getItem("user_token"),
        token_data: token_data,

        // 'country':this.$store.state.user.partner
      };
      api
        .request(process.env.VUE_APP_Get_repo_req_Data3, inputs)
        .then((response) => {
          const data = response.data;
          if (data.errorcode) {
            this.errormessage = data.errormessage;
          } else if (data.result && typeof data.result === "object") {
            this.data5 = data.result;
          } else if (data.result && typeof data.result === "string") {
            this.errormessage = data.result;
          }
        })
        .catch((error) => {
          this.errormessage = error;
        });
    },

    goToBuyback: function (id) {
      const extractedNumber = parseInt(id.substring(4));
      this.$router.push({
        path: "/admin/buyback/" + extractedNumber,
        params: { id: extractedNumber },
      });
    },
    goToGBB: function (ref) {
      this.$router.push({
        path: "/admin/gbb/" + ref,
        params: { ref: ref },
      });
    },
  },
  mounted() {
    if (!this.$store.state.user) {
      this.errormessage = "To view orders, please log in";
      this.$bvModal.show("modalSignIn");
    } else {
      this.getDetails();
      this.getDetails2();
    }
  },
};
</script>
